.container {
  display: flex;
  align-self: stretch;
}

.input {
  &:checked + span {
    border: 3px solid var(--brand-color-main);
    color: var(--brand-color-main);
    background-color: var(--white);
  }

  &:not(:checked) + span {
    cursor: pointer;

    &:hover {
      border: 3px solid var(--gray);
      color: var(--white);
      background-color: var(--gray);
    }
  }

  &:focus + span {
    outline: 2px dashed var(--brand-color-main);
  }
  @supports selector(input:focus-visible) {
    &:focus + span {
      outline: none;
    }
    &:focus-visible + span {
      outline: 2px dashed var(--brand-color-main);
    }
  }
}

.span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  border: 3px solid var(--gray);
  color: var(--gray);
  background-color: var(--white);
  transition: background 200ms;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2em;
  height: 100%;

  @media screen and (min-width: 45em) {
    padding: 1rem 2rem;
  }
}
